.sectionBg {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 200px;
    padding: 75px 0vw;
}

.curve {
    position: absolute;
    height: 225px;
    width: 100%;
    bottom: 0;
}

.curve::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    background: #1d201f;
    transform: translate(85%, 60%);
}

.curve::after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    background: #F3eff5;
    transform: translate(-4%, 40%);
    z-index: 1;

}

.triangle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.triangle svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 120px;
}

.triangle .shape-fill {
    fill: #1D201F;
}



.red {
    background-color: #e25822;
}

.magnolia {
    background-color: #F3eff5;
}

.eerieBlack {
    background-color: #1d201f;
}

.blue {
    background-color: #2274a5;
}

.yellow {
    background-color: #f9dc5c;
}


// new backgrounds

.blackSquares {
    width: 100%;
    height: 100%;
    --s: 200px;
    /* control the size */
    --c1: #1d1d1d;
    --c2: #4e4f51;
    --c3: #3c3c3c;

    background: repeating-conic-gradient(from 30deg,
            #0000 0 120deg,
            var(--c3) 0 180deg) calc(0.5 * var(--s)) calc(0.5 * var(--s) * 0.577),
        repeating-conic-gradient(from 30deg,
            var(--c1) 0 60deg,
            var(--c2) 0 120deg,
            var(--c3) 0 180deg);
    background-size: var(--s) calc(var(--s) * 0.577);
}


.whiteSquares {
    width: 100%;
    height: 100%;
    --s: 200px;
    /* control the size */
    --c1: #414141;
    --c2: #bebebe;
    --c3: #ffffff;

    background: repeating-conic-gradient(from 30deg,
            #0000 0 120deg,
            var(--c3) 0 180deg) calc(0.5 * var(--s)) calc(0.5 * var(--s) * 0.577),
        repeating-conic-gradient(from 30deg,
            var(--c1) 0 60deg,
            var(--c2) 0 120deg,
            var(--c3) 0 180deg);
    background-size: var(--s) calc(var(--s) * 0.577);
}

.whiteCard {
    background: white;
    border-radius: 10px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.shadowWhiteCard {
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
        0 0 0 2px rgb(190, 190, 190),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.blackCard {
    background: #1D1D1D;
    border-radius: 10px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.shadowCard {
    box-shadow: inset 0 -3em 3em rgba(255, 255, 255, 0.1),
        0 0 0 2px rgb(0, 0, 0),
        0.3em 0.3em 1em rgba(255, 255, 255, 0.3);
}
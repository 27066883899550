.input-field:empty {
    background-color: transparent;
    border: solid 1px #D6D6D6;
    color: #969696;
  }
  
  .input-field:focus {
    background-color: white;
    border-width: 2px;
    color: black;
  }
  
  .input-field:hover {
    border: solid 1px #D6D6D6;
  }
  
  .input-field {
    font-weight: lighter;
    font-size: 1px;
  }